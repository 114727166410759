import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
const myColors = [
  "#0099FF",
  "#A962FF",
  "#26CDCD",
  "#9FC51E",
  "#FF832B",
  "#FCBB53",
  "#21AF4A",
  "#66C2FF",
  "#4673FF",
  "#F28D8C",
  "#7D7DE8",
  "#AFEEEE",
  "#DA70D6",
  "#98FB98",
  "#F58CBD",
  "#FFDD44",
  "#89CFF0",
  "#FF6F61",
  "#B1EDE8",
  "#C9A0DC",
  "#93DFB8",
  "#FFD700",
  "#F4A460",
  "#ADD8E6",
  "#FFB347",
  "#77DD77",
  "#DDA0DD",
  "#FFC0CB",
  "#FF6961",
  "#B39EB5",
  "#FFB6C1",
];

const blurNumber = (value, blurChar = "█") => {
  if (value === null || value === undefined) return value;
  const valueStr = String(value);
  const blurredValue = blurChar.repeat(valueStr.length);
  return blurredValue;
};
const addOpacity = (hexColor, opacity) => {
  const hex = hexColor.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const myColorsWithOpacity = myColors.map((color) => addOpacity(color, 0.8));

const calculateBarWidth = (wrapperWidth, numberOfBars, paddingRatio) => {
  let barWidth =
    (wrapperWidth * (1 - paddingRatio)) /
    ((1 - paddingRatio) * numberOfBars + paddingRatio * (numberOfBars + 1));
  let barPadding =
    (wrapperWidth * paddingRatio) /
    ((1 - paddingRatio) * numberOfBars + paddingRatio * (numberOfBars + 1));
  barPadding = Math.floor(barPadding);
  barWidth = barWidth.toFixed(0);
  return { barWidth, barPadding };
};
const TransformedNivoBarGraph = ({ data }) => {
  const [transformedData, setTransformedData] = useState([]);
  const [transformedData2, setTransformedData2] = useState([]);
  const [isScale, setScale] = useState(false);
  const [isCurrency, setCurrency] = useState(false);
  const [wrapperWidth, setWrapperWidth] = useState(null);
  const wrapperRef = useRef(null);
  const legendPadding = 50;
  const fontSize = 9;
  const tickRotation = 0;
  const marginTop = 50;
  const marginRight = 50;
  const marginBottom = 70;
  const marginLeft = 60;
  const is_platform = false;
  const bigSize = 15;
  const smallSize = 5;
  const formatCode = "hi";
  const frequency = "M";
  const is_percent = false;
  const thousandScaling = false;
  const isBlurred = false;
  const legendDirection = "row";
  const topOption = [];
  const paddingRatio = 0.5;
  const showTotal = false;
  const is_usd = false;
  const currency_unit = isCurrency
    ? isScale
      ? ` (${is_usd ? "USD" : "INR"} ${is_usd ? "Bn" : "Mn"})`
      : ` (${is_usd ? "USD" : "INR"})`
    : isScale
      ? ` (${is_usd ? "Mn" : "Cr"})`
      : ``;
  const allDates = data?.Labels?.Data || [];
  useEffect(() => {
    if (!data || !data.Values || !data.Labels || !data.Labels.Data) {
      return;
    }
    try {
      let newTransformed = [];
      data.Values.forEach((series) => {
        data.Labels.Data.forEach((brand, index) => {
          const value = series.Data[index] || 0;
          if (value > 0) {
            newTransformed.push({
              year: series.Legend,
              value: value,
            });
          }
        });
      });
      let dataList = data.Values.map((item) => ({
        id: item.Legend,
        data: allDates.map((date, index) => ({
          x: date,
          y: item.Data[index] || null,
        })),
      }));
      setTransformedData(newTransformed);
      setTransformedData2(dataList);
    } catch (error) {
      console.error("Error transforming data:", error);
    }
  }, [data]);
  const CustomLegendLayer = ({ series }) => {
    const isRow = legendDirection === "row";
    const effectiveMarginLeft =
      marginLeft -
      barWidth / 2 +
      (wrapperWidth -
        transformedData.length * barWidth -
        (transformedData.length + 1) * barPadding) /
      2;

    let xPosition = legendPadding - effectiveMarginLeft;
    let yPosition = -marginTop + bigSize / 2;
    return (
      <g>
        {series.map((item, index) => {
          const xCurrentPosition = xPosition;
          xPosition = (item.id.length * fontSize) / 2 + xPosition + 30;
          const yCurrentPosition = yPosition;
          yPosition = yPosition + 12;
          return (
            <g
              key={item.id}
              transform={
                isRow
                  ? `translate(${xCurrentPosition},${-marginTop + bigSize / 2
                  })`
                  : `translate(${legendPadding - effectiveMarginLeft
                  },${yCurrentPosition})`
              }
            >
              <circle cx={10} cy={0} r={bigSize / 2} fill={item.color} />
              <text
                x={20}
                y={0}
                textAnchor="start"
                alignmentBaseline="middle"
                fill="#000000"
                fontSize={fontSize}
              >
                {item.id}
              </text>
            </g>
          );
        })}
      </g>
    );
  };

  if (!transformedData || transformedData.length === 0) {
    return <div>Loading graph data...</div>;
  }

  const CustomTooltip = ({ slice }) => {
    const reversedData = [...slice.points].reverse();
    const topData = reversedData.filter(
      (obj) =>
        (topOption.length === 0 || topOption.includes(obj.serieId)) &&
        obj.serieId !== "Overall"
    );
    const totalValue = topData.reduce(
      (sum, dataPoint) => sum + dataPoint.data.y,
      0
    );
    const totalValueList = reversedData.filter(
      (obj) => obj.serieId === "Overall"
    );

    const otherData = reversedData.filter(
      (obj) => !topData.includes(obj) && obj.serieId !== "Overall"
    );
    return (
      <TootTipWrapper fontSize={fontSize}>
        {showTotal && totalValueList.length && (
          <TootTipRow>
            <TootTipLabel bold={true}>
              <SquareBox size={bigSize} color={"#cccccc"} bold={true} />
              Overall
            </TootTipLabel>
            <TootTipValue bold={true}>
              {/* {Number(totalValue.toFixed(2)).toLocaleString(formatCode, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} */}
              {totalValueList[0].data.yFormatted}
              {is_percent ? "%" : ""}
              <TootTipValueUnit fontSize={Number(fontSize * 0.8)} bold={true}>
                {!is_percent ? currency_unit : ""}
              </TootTipValueUnit>
            </TootTipValue>
          </TootTipRow>
        )}
        {topData.map((point, idx) => {
          const squareColor = point.data.x.endsWith("(P)") ? "#ADD8E6" : point.serieColor;
          return (
            <TootTipRow key={idx}>
              <TootTipLabel>
                <SquareBox size={bigSize} color={squareColor} />
                {point.serieId}
              </TootTipLabel>
              <TootTipValue>
                {isBlurred
                  ? blurNumber(point.data.yFormatted)
                  : point.data.yFormatted}
                {is_percent ? "%" : ""}
                <TootTipValueUnit fontSize={Number(fontSize * 0.8)}>
                  {!is_percent ? currency_unit : ""}
                </TootTipValueUnit>
              </TootTipValue>
            </TootTipRow>
          );
        })}
        {otherData.map((point, idx) => (
          <TootTipOtherRow key={idx} margin={bigSize}>
            <TootTipLabel>- {point.serieId}</TootTipLabel>
            <TootTipValue>
              {isBlurred
                ? blurNumber(point.data.yFormatted)
                : point.data.yFormatted}
              {is_percent ? "%" : ""}
              <TootTipValueUnit fontSize={Number(fontSize * 0.8)}>
                {!is_percent ? currency_unit : ""}
              </TootTipValueUnit>
            </TootTipValue>
          </TootTipOtherRow>
        ))}
      </TootTipWrapper>
    );
  };

  const LabelLayer = (data) => {
    let decimalCount = 2;
    if (wrapperWidth < 450) {
      decimalCount = 1;
    }
    if (wrapperWidth < 350) {
      decimalCount = 0;
    }
    return (
      <g>
        {data.bars.map((bar) => {
          let legendVisible = false;
          if (bar.data.value && bar.data.value.toFixed(decimalCount)) {
            legendVisible = true;
          }
          const scaleThousand = thousandScaling && bar.data.value > 1000;
          const pointDecimal = scaleThousand
            ? bar.data.value > 10000
              ? 0
              : 1
            : decimalCount;

          const formattedValue = legendVisible
            ? `${Number(
              (scaleThousand
                ? bar.data.value / 1000
                : bar.data.value
              ).toFixed(pointDecimal)
            ).toLocaleString(formatCode, {
              minimumFractionDigits: pointDecimal,
              maximumFractionDigits: pointDecimal,
            })}${scaleThousand ? "K" : ""}${is_percent ? "%" : ""}`
            : null;

          // Apply blur if enabled
          const displayValue = legendVisible && isBlurred
            ? blurNumber(formattedValue)
            : formattedValue;
          const isProjected = bar?.data?.data?.year?.endsWith("(P)");
          return (
            <text
              key={bar.key}
              x={bar.x + bar.width / 2}
              y={bar.y + bar.height / 2 + fontSize / 2}
              textAnchor="middle"
              style={{
                fill: "#262e40",
                fontSize: fontSize,
                fontWeight: isProjected ? "bold" : "normal",
                filter: isBlurred ? "blur(10px)" : "none",
              }}
            >
              {displayValue}
            </text>
          );
        })}
      </g>
    );
  };
  const updateWrapperWidth = () => {
    if (wrapperRef.current) {
      const newWidth = wrapperRef.current.offsetWidth;
      if (newWidth) {
        setWrapperWidth(newWidth - marginLeft - marginRight);
      }
    }
  };
  const { barWidth, barPadding } = calculateBarWidth(
    wrapperWidth,
    transformedData.length,
    paddingRatio
  );
  return (
    <Wrapper ref={wrapperRef}>
      <PositionWrapper>
        <GraphWrapper isBlurred={isBlurred}>
          <ResponsiveBar
            data={transformedData}
            keys={["value"]}
            indexBy="year"
            margin={{
              top: marginTop,
              right: marginRight,
              bottom: marginBottom,
              left: marginLeft,
            }}
            //  tooltip={CustomTooltipBar}
            padding={paddingRatio}
            //   width={0.5}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={is_platform
              ? (series) => {
                if (!myColors || !myColors[series.id]) {
                  console.warn(`Color mapping missing for series: ${series.id}`);
                  return "#FAEBD7"; // Default color if mapping is missing
                }
                return addOpacity(myColors[series.id], 0.8);
              }
              : myColorsWithOpacity}
            borderColor={{
              from: "color",
              modifiers: [["darker", "0.1"]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: tickRotation,
              legend: null,
              legendOffset: 36,
              legendPosition: "middle",
              truncateTickAt: 0,
            }}
            axisLeft={null}
            enableGridY={false}
            totalsOffset={0}
            labelTextColor={{
              from: "color",
              modifiers: [["darker", "1.6"]],
            }}
            legends={[]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            enableLabel={false}
            barAriaLabel={(e) =>
              e.id + ": " + e.formattedValue + " in country: " + e.indexValue
            }
            isInteractive={false}
            //   tooltip={CustomTooltip}
            layers={["grid", "axes", "bars", "markers", "legends", LabelLayer]}
          />
        </GraphWrapper>
        <GraphWrapper2 isBlurred={isBlurred}>
          <ResponsiveLine
            data={transformedData2}
            colors={
              is_platform
                ? (series) => {
                  if (!myColors || !myColors[series.id]) {
                    console.warn(`Color mapping missing for series: ${series.id}`);
                    return "#FAEBD7"; // Default color if mapping is missing
                  }
                  return myColors[series.id];
                }
                : myColors
            }
            margin={{
              top: marginTop,
              right:
                marginRight -
                barWidth / 2 +
                (wrapperWidth -
                  transformedData.length * barWidth -
                  (transformedData.length + 1) * barPadding) /
                2,
              bottom: marginBottom,
              left:
                marginLeft -
                barWidth / 2 +
                (wrapperWidth -
                  transformedData.length * barWidth -
                  (transformedData.length + 1) * barPadding) /
                2,
            }}
            theme={{
              background: "#ffffff00",
              text: {
                fontSize: fontSize,
                fill: "#333333",
                outlineWidth: 0,
                outlineColor: "transparent",
              },
              tooltip: {
                wrapper: {},
                container: {
                  background: "#ffffff",
                  color: "#333333",
                  fontSize: fontSize,
                },
                basic: {},
                chip: { height: bigSize, width: bigSize },
                table: {},
                tableCell: {},
                tableCellValue: {},
              },
            }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            yFormat={(value) => {
              const formatted = `${Number(value.toFixed(2)).toLocaleString(formatCode, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`;
              return isBlurred ? blurNumber(formatted) : formatted;
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={false}
            enableGridX={false}
            enableGridY={false}
            lineWidth={0}
            enablePoints={false}
            pointSize={smallSize}
            pointColor={{ from: "color", modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "color", modifiers: [] }}
            enablePointLabel={false}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            areaOpacity={0}
            enableSlices="x"
            enableCrosshair={true}
            enableTouchCrosshair={true}
            useMesh={false}
            legends={[]}
            motionConfig="default"
            layers={[
              "grid",
              "markers",
              "areas",
              "lines",
              "slices",
              "points",
              "axes",
              "crosshair",
              CustomLegendLayer,
            ]}
            sliceTooltip={CustomTooltip}
          />
        </GraphWrapper2>
        <LeftCover width={marginLeft + barPadding / 2 + 2} />
        <RightCover width={marginRight + barPadding / 2 + 2} />
      </PositionWrapper>
    </Wrapper>
  );
};

export default TransformedNivoBarGraph;
const TootTipWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 8px;
    border: 0.5px solid #cccccc;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    font-size: ${(props) => props.fontSize}px;
 `;
  
  const TootTipRow = styled.div`
    width: 100%;
    display: flex;
    padding: 2.5px;
    flex-direction: row;
    justify-content: space-between;
  `;
  
  const TootTipOtherRow = styled.div`
    width: 100%;
    display: flex;
    padding: 2.5px;
    flex-direction: row;
    justify-content: space-between;
    padding-left: ${(props) => 12.5 + props.margin}px;
  `;
  
  const TootTipLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    font-weight: ${(props) => (props.bold ? "500" : "400")};
  `;
  
  const SquareBox = styled.div`
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
    background-color: ${(props) => props.color};
    margin-right: 10px;
    border: ${(props) =>
      props.bold ? "1.5px solid #262e40" : "0px solid #262e40"};
  `;
  
  const TootTipValue = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: ${(props) => (props.bold ? "500" : "400")};
  `;
  
  const TootTipValueUnit = styled.div`
    font-size: ${(props) => props.fontSize}px;
    margin-left: 3px;
    color: #a0a6a9;
    font-weight: 350;
    font-weight: ${(props) => (props.bold ? "500" : "350")};
  `;
  
  const GraphWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
  `;
  
  const GraphWrapper2 = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: all;
  `;
  
  const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
  `;

  const PositionWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const LeftCover = styled.div`
  position: absolute;
  width: ${(props) => props.width}px;
  height: 100%;
  top: 0;
  left: 0;
`;

const RightCover = styled.div`
  position: absolute;
  width: ${(props) => props.width}px;
  height: 100%;
  top: 0;
  right: 0;
`;
