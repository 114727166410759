import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MasterTable from "../../components/MasterTable";
import { media } from "../../utils/media";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { chatHisrtoryData, fetchChatHistoryData, fetchClientsIdsData, selectnewClientIdsData } from "../../utils/redux/slices";
import { Button, Pagination } from "antd";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import TransformedNivoLineGraph from "./TransformedLineGraph";
import TransformedNivoBarGraph from "./TransformedBarGraph";
import FeedbackTextWithToggle from "./FeedBackWithToggle";

const ChatHistory = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [selectedChartData, setSelectedChartData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const is_admin =
    window.localStorage.getItem("is_admin") === "true" ? true : false;
  const [columnsData, setColumnsData] = useState([
    {
      key: "client_id",
      value: <ColumnText>Client Name</ColumnText>,
      sorting: false,
      range_filter: false,
      value_filter: false,
      min_width: "80px",
     fontSize: "12px"
    },
    {
      key: "email",
      value: <ColumnText>Email</ColumnText>,
      sorting: false,
      range_filter: false,
      value_filter: false,
      min_width: "120px",
     fontSize: "12px"
    },
    {
      key: "question",
      value: <ColumnText>Question</ColumnText>,
      sorting: false,
      range_filter: false,
      value_filter: false,
      min_width: "150px",
     fontSize: "12px"
    },
    {
      key: "answer",
      value: <ColumnText>Answer</ColumnText>,
      range_filter: false,
      value_filter: false,
      min_width: "150px",
      fontSize: "12px"
    },
    {
      key: "date",
      value: <ColumnText>Date</ColumnText>,
      sorting: true,
      sortDirection: 'descend', // Default sort descending
      range_filter: false,
      value_filter: false,
      min_width: "100px",
      fontSize: "12px"
    },
    {
      key: "time",
      value: <ColumnText>Time</ColumnText>,
      sorting: false,
      range_filter: false,
      value_filter: false,
      min_width: "80px",
      fontSize: "12px"
    },
    {
      key: "query_response_time",
      value: <ColumnText>Response Time</ColumnText>,
      range_filter: false,
      value_filter: false,
      min_width: "80px",
     fontSize: "12px"
    },
    {
      key: "data_source",
      value: <ColumnText>Category</ColumnText>,
      range_filter: false,
      value_filter: false,
      min_width: "100px",
      fontSize: "12px"
    },
    {
      key: "feedback",
      value: <ColumnText>Feedback</ColumnText>,
      range_filter: false,
      value_filter: false,
      min_width: "80px",
      fontSize: "12px"
    }
  ]);
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const chatHistoryData = useSelector(chatHisrtoryData);
  const [styledRowsData, setStyledRowsData] = useState([]);
  const navigate = useNavigate();
  const clientIdsData = useSelector(selectnewClientIdsData);
  const showModal = (answer) => {
    try {
      const parsedAnswer = JSON.parse(answer);
      if (parsedAnswer?.data?.Type) {
        setSelectedAnswer(parsedAnswer.insightText || "No insight text available");
        setSelectedChartData(parsedAnswer.data);
      } else if (parsedAnswer?.Chart) {
        setSelectedAnswer(parsedAnswer.insightText || "No insight text available");
        setSelectedChartData(parsedAnswer.Chart);
      } else {
        setSelectedAnswer(answer);
        setSelectedChartData(null);
      }
    } catch (e) {
      setSelectedAnswer(answer);
      setSelectedChartData(null);
    }
    setIsModalVisible(true);
  };
  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = "hidden"; 
    } else {
      document.body.style.overflow = "auto"; 
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalVisible]);
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedAnswer("");
    setSelectedChartData(null);
  };
  const truncateText = (text, limit = 50) => {
    if (!text) return "";
    if (text.length <= limit) return text;
    return `${text.substring(0, limit)}...`;
  };

  useEffect(() => {
    dispatch(fetchChatHistoryData());
  }, [dispatch]);

  useEffect(() => {
    if (chatHistoryData?.length > 0) {
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const paginatedData = chatHistoryData?.slice(startIndex, endIndex);
      const clientNameMap = clientIdsData?.reduce((acc, client) => {
        acc[client.id] = client.name;
        return acc;
      }, {});
      const formattedData = paginatedData?.map((item) => {
        const clientName = clientNameMap?.[item?.client_id] || item?.client_id; 
        return {
          client_id: {
            value: <TextCell>{clientName}</TextCell> 
          },
          email: {
            value: <TextCell>{item.email}</TextCell>
          },
          question: {
            value: <TextCell>{item.question}</TextCell>
          },
          answer: {
            value: (
              <AnswerCell>
                {truncateText(item.answer)}
                {item.answer && item.answer.length > 50 ? (
                  <ViewButton onClick={() => showModal(item.answer)}>
                    View
                  </ViewButton>
                ) : (
                  item.answer === null || item.answer === "" ? "-" : null
                )}
              </AnswerCell>
            )
          },
          date: {
            value: (
              <TextCell>
                {moment(item.created).format("Do MMM YYYY")}
              </TextCell>
            )
          },
          time: {
            value: (
              <TextCell>
                {moment(item.created).format("h:mm a")}
              </TextCell>
            )
          },
          query_response_time: {
            value: <TextCell>{item.query_response_time} s</TextCell>
          },
          data_source: {
            value: <TextCell>{item.data_source}</TextCell>
          },
          feedback: {
            value: (
              <FeedbackCell>
                {item.positive_feedback > 0 && (
                  <PositiveFeedback>👍</PositiveFeedback>
                )}
                {item.negative_feedback > 0 && (
                  <NegativeFeedback>
                    👎{' '}
                    {item.feedback && item.feedback.length > 50 ? (
                      <FeedbackTextWithToggle text={item.feedback} />
                    ) : (
                      item.feedback || '-'
                    )}
                  </NegativeFeedback>
                )}
                {!item.positive_feedback && !item.negative_feedback && "-"}
              </FeedbackCell>
            )
          }
        };
      });
      setStyledRowsData(formattedData);
    }
  }, [chatHistoryData,currentPage,pageSize,clientIdsData]);

  useEffect(() => {
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          handleCancel();
        }
      };
      if (isModalVisible) {
        document.addEventListener('mousedown', handleClickOutside);
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
  }, [isModalVisible]);

  useEffect(() => {
      dispatch(fetchClientsIdsData());
  }, [dispatch]);

  useEffect(()=>{
   if(is_admin === false){
    navigate("/internet-landscape");
   }
  },[is_admin])
  return (
    <PageWrapper>
      <Header />
      <GridWraper>
        <MasterTable
          columnsData={columnsData}
          rowsData={styledRowsData}
          headerBackground="#FFFFFF"
          autoCellHeight={true}
          toolGap={-4}
        />
        {styledRowsData.length !== 0 && styledRowsData.length > 0 && <PaginationContainer>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={chatHistoryData?.length || 0}
            onChange={(page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            }}
            showSizeChanger
            pageSizeOptions={['10', '20', '50', '100']}
          />
        </PaginationContainer>}
      </GridWraper>
      <Footer />
      {
        isModalVisible &&
          <ModalContainer>
           <ModalContent ref={modalRef}>
            <ModalHeader>
              <h2>Detailed Answer</h2>
              <CloseButton onClick={handleCancel}>
                <MdClose size={24} />
              </CloseButton>
            </ModalHeader>
              <ModalBody>
                <AnswerText>{selectedAnswer}</AnswerText>
                {selectedChartData && (
                  <ChartContainer>
                    {selectedChartData.Type === "Line" ? (
                      <TransformedNivoLineGraph data={selectedChartData} />
                    ) : (
                      <TransformedNivoBarGraph data={selectedChartData} />
                    )}
                  </ChartContainer>
                )}
             </ModalBody>
            <ModalFooter>
                <Button key="back" onClick={handleCancel}>
                  Close
                </Button>
            </ModalFooter>
          </ModalContent>
        </ModalContainer>
      }
    </PageWrapper>
  );
};

export default ChatHistory;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eef9ff;
  min-height: 100vh;
`;

const GridWraper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 3.5vw;
  margin-bottom: 10px;
  min-height: 85vh;
  gap: 20px;
  ${media.small`
    margin-bottom: 10px;
    padding: 15px;
  `}
`;

const TextCell = styled.div`
  font-weight: 400;
  color: #333;
  font-size: 14px;
  padding: 4px 0;
  line-height: 1.4;
  ${media.small`
    font-size: 12px;
  `}
`;

const AnswerCell = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ViewButton = styled.button`
  background: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background: #40a9ff;
  }
`;

const FeedbackCell = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const PositiveFeedback = styled.span`
  color: #10b981;
  font-weight: 500;
`;

const NegativeFeedback = styled.span`
  color: #ef4444;
  font-weight: 500;
`;

const ColumnText = styled.div`
  font-size: 16px;
  ${media.small`
    font-size: 12px;
  `}
`;

const AnswerText = styled.div`
  white-space: pre-wrap;
  word-break: break-word;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 4px;
  max-height: 60vh;
  overflow-y: auto;
`;

const ChartContainer = styled.div`
  height: 350px; 
  margin-top: 10px;  
  width: 100%;
  overflow: hidden;
  position: relative;
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      height: 340px;
      -webkit-overflow-scrolling: touch;
      overscroll-behavior: contain;
    }
  }
  ${media.small`
    height: 270px; 
    margin-top: 10px;  
    width: 100%;
  `}
`;

const ModalContainer = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;

  ${media.small`
    width: 90%;
    margin: 20% auto;
  `}
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  h2 {
    font-size: 24px;
  }
`;

const ModalBody = styled.div`
  margin-top: 20px;
  max-height: 60vh;
  overflow-y: auto;
  flex-direction: column;
  gap: 20px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  ${media.small`
    font-size: 20px;
  `}
`;
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 10px 0;
  
  .ant-pagination {
    font-size: 14px;
  }
  
  ${media.small`
    .ant-pagination-item,
    .ant-pagination-options {
      margin: 0 2px;
    }
  `}
`;